import { lazy, FC, Suspense, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { PublicLayout } from '../../_metronic/layout/PublicLayout'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import DescriptionPage from '../modules/schedule/public/DescriptionPage'

const PublicRoutes = () => {
  const SchedulePage = lazy(() => import('../modules/schedule/public/SchedulePage'))

  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route
          path='schedules/*'
          element={
            <SuspensedView>
              <SchedulePage />
            </SuspensedView>
          }
        />
      </Route>
      <Route element={<PublicLayout />}>
        <Route
          path='document/*'
          element={
            <SuspensedView>
              <DescriptionPage />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PublicRoutes }
