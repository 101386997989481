import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {
  Info,
  ResponseUser,
  User
} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/api/usuarios`

const updateOnboardingStatus = (status: boolean): Promise<ResponseUser | undefined> => {
  return axios
  .put(`${USER_URL}/update-onboarding`, {'onboarded': status})
    .then((response: AxiosResponse<Response<ResponseUser>>) => response.data)
    .then((response: Response<ResponseUser>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(`${USER_URL}/update-working-day`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

export {
  updateOnboardingStatus,
  updateUser
}
