import { FormikProps } from 'formik'
import { ID, Response } from '../../../../../_metronic/helpers'

interface IconUserModel {
  name: string
  avatar?: string
  color?: string
  initials?: string
}

export type Project = {
  id?: string
  name: string
  client_id: string
  begin_date: string
  end_date: string
  budget?: number
  type?: number
  categories?: string[]
  clientId?: number
  clientName?: string
  users?: Array<IconUserModel>
  budgeted_hours?: number
  closed_at?: string | null
  cycle?: number
  description?: string
  shared_description?: string
  amount_spent?: string
  public_url?: string;
  profit_margin?: number
  totalTime?: number
  current_budget?: number
  instance_id?: number
  total_tasks?: number
  cycles?: ProjectCycle[]
}

export type ProjectCycle = {
  id: number
  project_id: number
  day: number
  budget: number
  budgeted_hours: number
  begin_date: Date
  end_date: Date
  created_at: Date
  updated_at: Date
}

export type ProjectCycleVew = {
  budgeted_hours?: number
  used_time?: number
  used_time_percentage?: number
  cycles_filter?: [
    begin: string,
    end: string
  ][]
}

export type OverviewRecentActivitiesModel = {
  task: {
    project_id: number
    project_name: string
    task_id: number
    userName: string
    userAvatar: string
    created_at: string
    description: string
  }[]
  project: {
    id: number
    event: string
    project_id: number
    user_id: number
    user?: { id: number, name: string, avatar: string | null }
    when: Date
    auditable_type: string
    auditable_id: number
    details?: { old: { [key: string]: any }, new: { [key: string]: any }, period?: string }
  }[]
}


export type ResponseProject = {
  project: Project
  message?: string
  errors?: boolean
}

export type OverviewRecentActivitiesQueryResponse = Response<OverviewRecentActivitiesModel>
export type ProjectsQueryResponse = Response<Array<Project>>

export const initialProject: Project = {
  name: '',
  client_id: '',
  begin_date: '',
  end_date: '',
  budget: 0,
  type: 1,
  categories: [],
  budgeted_hours: 0
}

export type StepProps = {
  data: Project,
  updateData: (fieldsToUpdate: Partial<Project>) => void,
  hasError: boolean
  props?: FormikProps<Project>
}