import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../helpers'
import {useMutation} from 'react-query'
import {updateOnboardingStatus} from '../../../../../app/pages/onboarding/core/_requests'
import Swal from 'sweetalert2'
import {useAuth} from '../../../../../app/modules/auth'
import {useState} from 'react'

type Props = {
  className?: string
}

export const LimitUsersWidget = ({className}: Props) => {
  const navigate = useNavigate()
  const {currentUser, setCurrentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const update = useMutation(() => updateOnboardingStatus(true), {
    onSuccess: (response) => {
      if (!currentUser?.onboarded) setCurrentUser({...currentUser!, onboarded: 1})
      setLoading(false)

      return navigate('/billings')
    },
    onError: (error: any) => {
      setLoading(false)
      Swal.fire({
        icon: 'warning',
        text: error.response.data.errors.email[0],
      })
    },
  })

  return (
    <div className={`card card-flush ${className}`}>
      <div
        className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
        style={{
          backgroundPosition: '100% 50%',
          backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
        }}
      >
        <div className='mb-10'>
          <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
            <p className='me-2'>
              Você atingiu o número máximo de usuários permitidos no seu plano atual.
            </p>
            <p className='fs-3'>
              {!currentUser?.manager
                ? 'Faça o upgrade para aumentar o limite e continue criando novos usuários!'
                : 'Entre em contato com o administrador da empresa para solicitar o upgrade do plano e continuar criando novos usuários!'}
            </p>
          </div>

          <div className='text-center'>
            {!currentUser?.manager &&
              (!currentUser?.onboarded ? (
                <button
                  disabled={loading}
                  className='btn btn-danger'
                  onClick={async () => {
                    setLoading(true)
                    await update.mutateAsync()
                  }}
                >
                  Pular Onboarding e Fazer o Upgrade
                </button>
              ) : (
                <Link to='/billings' className='btn btn-danger'>
                  Faça o Upgrade Agora
                </Link>
              ))}
          </div>
        </div>
        <img
          className='mx-auto h-150px h-lg-200px  theme-light-show'
          src={toAbsoluteUrl('/media/illustrations/misc/upgrade.svg')}
          alt=''
        />
        <img
          className='mx-auto h-150px h-lg-200px  theme-dark-show'
          src={toAbsoluteUrl('/media/illustrations/misc/upgrade-dark.svg')}
          alt=''
        />
      </div>
    </div>
  )
}
