import axios, { AxiosResponse } from 'axios'
import { ID, QueryState, Response } from '../../../../../_metronic/helpers'
import {
  OverviewRecentActivitiesQueryResponse,
  Project,
  ProjectCycleVew,
  ProjectsQueryResponse,
  ResponseProject,
} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const PROJECT_URL = `${API_URL}/api/projetos`
const GET_PUBLIC_URL = `${API_URL}/api/public/project`
const GET_PROJECTS_URL = `${API_URL}/api/projetos`

const getProjects = (): Promise<{ data: ProjectsQueryResponse; isEmptyResults: boolean }> => {
  return axios
    .get(GET_PROJECTS_URL)
    .then((d: AxiosResponse<{ data: ProjectsQueryResponse; isEmptyResults: boolean }>) => d.data)
}

const getProjectsTask = (id: ID): Promise<ProjectsQueryResponse> => {
  return axios
    .get(`${PROJECT_URL}/${id}/moreprojects`)
    .then((d: AxiosResponse<ProjectsQueryResponse>) => d.data)
}

const getProjectsTaskByUserId = (): Promise<ProjectsQueryResponse> => {
  return axios
    .get(`${PROJECT_URL}/moreprojects-by-userid`)
    .then((d: AxiosResponse<ProjectsQueryResponse>) => d.data)
}

const getProjectsList = (query: string): Promise<{ data: ProjectsQueryResponse; isEmptyResults: boolean }> => {
  return axios
    .get(`${GET_PROJECTS_URL}?${query}`)
    .then((d: AxiosResponse<{ data: ProjectsQueryResponse; isEmptyResults: boolean }>) => d.data)
}

const searchProject = (search: QueryState): Promise<ProjectsQueryResponse> => {
  return axios
    .post(`${PROJECT_URL}/search`, search)
    .then((d: AxiosResponse<ProjectsQueryResponse>) => d.data)
}

const searchProjectByFilterSelect = (status: ID): Promise<ProjectsQueryResponse> => {
  return axios
    .get(`${PROJECT_URL}/${status}/searchProjectByFilterSelect`)
    .then((d: AxiosResponse<ProjectsQueryResponse>) => d.data)
}

const getOverviewRecentActivities = (
  projectId: ID
): Promise<OverviewRecentActivitiesQueryResponse> => {
  return axios
    .get(`${PROJECT_URL}/${projectId}/getOverviewRecentActivities`)
    .then((d: AxiosResponse<OverviewRecentActivitiesQueryResponse>) => d.data)
}

const searchProjectByClient = (search: QueryState): Promise<ProjectsQueryResponse> => {
  return axios
    .post(`${PROJECT_URL}/search-by-client`, search)
    .then((d: AxiosResponse<ProjectsQueryResponse>) => d.data)
}

const getProjectById = (id: ID): Promise<Project> => {
  return axios
    .get(`${PROJECT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Project>>) => response.data)
    .then((response: Response<Project>) => response.data)
}

const getProjectByPublicUrl = (id: string): Promise<Project> => {
  return axios
    .get(`${GET_PUBLIC_URL}/${id}`)
    .then((response: AxiosResponse<Response<Project>>) => response.data)
    .then((response: Response<Project>) => response.data)
}

const getProjectCycleView = (id: ID): Promise<ProjectCycleVew> => {
  return axios
    .get(`${PROJECT_URL}/${id}/cycle-view`)
    .then((response: AxiosResponse<Response<ProjectCycleVew>>) => response.data)
    .then((response: Response<ProjectCycleVew>) => response.data)
}

const getProjectByClientId = (id: string | undefined): Promise<ProjectsQueryResponse> => {
  return axios
    .get(`${PROJECT_URL}/${id}/byClient`)
    .then((response: AxiosResponse<ProjectsQueryResponse>) => response.data)
}

const createProject = (project: Project): Promise<ResponseProject | undefined> => {
  return axios
    .post(`${PROJECT_URL}/novo`, project)
    .then((response: AxiosResponse<ResponseProject>) => response.data)
}

const updateProject = (
  project: Partial<Project> & { cycleOption?: string; cycleId?: number }
): Promise<ResponseProject> => {
  return axios
    .put(`${PROJECT_URL}/${project.id}/editar`, project)
    .then((response: AxiosResponse<Response<ResponseProject>>) => response.data)
    .then((response: Response<ResponseProject>) => response.data)
}

const removeSharedDescriptionProject = (
  project: Partial<Project> & { cycleOption?: string; cycleId?: number }
): Promise<ResponseProject> => {
  return axios
    .post(`${PROJECT_URL}/${project.id}/deletar-documento`)
    .then((response: AxiosResponse<Response<ResponseProject>>) => response.data)
    .then((response: Response<ResponseProject>) => response.data)
}

const deleteProject = (projectId: ID): Promise<void> => {
  return axios.delete(`${PROJECT_URL}/${projectId}/excluir`).then(() => { })
}

const openProject = (projectId: ID): Promise<void> => {
  return axios.post(`${PROJECT_URL}/${projectId}/abrir`).then(() => { })
}

export {
  getProjects,
  getProjectByClientId,
  searchProject,
  searchProjectByFilterSelect,
  searchProjectByClient,
  getOverviewRecentActivities,
  deleteProject,
  getProjectById,
  createProject,
  updateProject,
  openProject,
  getProjectsList,
  getProjectsTask,
  getProjectsTaskByUserId,
  getProjectCycleView,
  getProjectByPublicUrl,
  removeSharedDescriptionProject
}
