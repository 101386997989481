/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useRef, useEffect, useState, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {Outlet, useParams, useNavigate, useSearchParams} from 'react-router-dom'
import {useMutation} from 'react-query'
import Swal from 'sweetalert2'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../_metronic/helpers'
import {updateOnboardingStatus} from '../core/_requests'
import {Loading} from '../../../../_metronic/partials/components/loading/Loading'
import {useAuth} from '../../../modules/auth'
import {StepClients} from './steps/StepClients'

const ManagerOnboardingPageWrapper: FC = () => {
  const intl = useIntl()
  const {step} = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const {currentUser, setCurrentUser} = useAuth()
  const stepperRefOnboarding = useRef<HTMLDivElement | null>(null)
  const stepperOnboarding = useRef<StepperComponent | null>(null)
  const stepperNavRef = useRef<Array<HTMLDivElement | null>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const currentUserCostHour = useMemo(() => currentUser?.cost_hour, [])
  const CurrentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''
  const theme = CurrentTheme === 'dark' ? '#353b48' : '#fff'

  const navItems = [
    {
      title: intl.formatMessage({id: 'ONBOARDING.TITLE.WELCOME'}),
      description: intl.formatMessage({id: 'ONBOARDING.DESCRIPTION.WELCOME'}),
    },
    {
      title: intl.formatMessage({id: 'ONBOARDING.TITLE.USERS'}),
      description: intl.formatMessage({id: 'ONBOARDING.DESCRIPTION.USERS'}),
    },
    {
      title: intl.formatMessage({id: 'ONBOARDING.TITLE.CLIENTS'}),
      description: intl.formatMessage({id: 'ONBOARDING.DESCRIPTION.CLIENTS'}),
    },
    {
      title: intl.formatMessage({id: 'ONBOARDING.TITLE.PROJECTS'}),
      description: intl.formatMessage({id: 'ONBOARDING.DESCRIPTION.PROJECTS'}),
    },
    {
      title: intl.formatMessage({id: 'ONBOARDING.TITLE.TASKS'}),
      description: intl.formatMessage({id: 'ONBOARDING.DESCRIPTION.TASKS'}),
    },
  ]

  if (!currentUser?.manager && !currentUser?.onboarded && !currentUserCostHour) {
    navItems.splice(1, 0, {
      title: intl.formatMessage({id: 'ONBOARDING.TITLE.WORKING_DAY'}),
      description: intl.formatMessage({id: 'ONBOARDING.DESCRIPTION.WORKING_DAY'}),
    })
  }

  useEffect(() => {
    stepperOnboarding.current = StepperComponent.createInsance(
      stepperRefOnboarding.current as HTMLDivElement
    )
  }, [])

  useEffect(() => {
    if (step) {
      stepperNavRef.current.map((element, index) => {
        element?.classList.remove('current')
        element?.classList.remove('completed')
        if (index < parseInt(step) - 1) {
          element?.classList.add('completed')
        }
      })
      stepperNavRef.current[parseInt(step) - 1]?.classList.add('current')
    } else {
      stepperNavRef.current[0]?.classList.add('current')
    }
  }, [step])

  const nextStep = async () => {
    const nextStep = step ? parseInt(step!) + 1 : 2
    const response =
      [2, 3, 4].includes(parseInt(step!)) && searchParams.get('hasChange') == '1'
        ? await Swal.fire({
            title: intl.formatMessage({id: 'ALERT.LABEL.ARE_YOU_SURE'}),
            html: 'Os dados preenchidos não foram salvos e serão perdidos ao continuar. Deseja prosseguir?',
            icon: 'warning',
            background: theme,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim, continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
          })
        : undefined

    return response
      ? response.isConfirmed && navigate(`/onboarding/${nextStep}`)
      : navigate(`/onboarding/${nextStep}`)
  }

  const prevStep = () => navigate(`/onboarding/${parseInt(step!) - 1}`)

  const handleFinish = () => {
    setIsLoading(true)
    update.mutateAsync()
  }

  const update = useMutation(() => updateOnboardingStatus(true), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: (response) => {
      setIsLoading(false)
      if (!currentUser?.onboarded) {
        setCurrentUser({...currentUser!, onboarded: 1})
      }
      return navigate('/dashboard')
    },
    onError: (error: any) => {
      setIsLoading(false)
      Swal.fire({
        icon: 'warning',
        text: error.response.data.errors.email[0],
      })
    },
  })

  return (
    <div className='d-flex flex-column min-vh-100' id='kt_app_root'>
      <div
        ref={stepperRefOnboarding}
        className='d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep'
        id='kt_onboarding_stepper'
      >
        <div className='d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px'>
          <div className='d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y bgi-size-cover bgi-position-center'>
            <div className='d-flex flex-center py-10 py-lg-15'>
              <img
                alt='TaskRush'
                src={toAbsoluteUrl('/media/logos/taskrush.svg')}
                className='h-70px'
              />
            </div>

            <div className='d-flex flex-row-fluid justify-content-center p-10'>
              <div className='stepper-nav'>
                {navItems.map((item, index) => (
                  <div
                    key={index}
                    ref={(el) => (stepperNavRef.current![index] = el)}
                    className='stepper-item'
                    data-kt-stepper-element='nav'
                  >
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon rounded-3'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>{index + 1}</span>
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title fs-2'>{item.title}</h3>
                        <div className='stepper-desc fw-normal'>{item.description}</div>
                      </div>
                    </div>
                    {index + 1 < navItems.length && <div className='stepper-line h-40px'></div>}
                  </div>
                ))}
              </div>
            </div>

            <div className='d-flex flex-center flex-wrap px-5 pt-5 pb-10'>
              <button
                type='button'
                className='btn btn-lg btn-link link-primary p-0'
                onClick={handleFinish}
              >
                {intl.formatMessage({id: 'GENERAL.SKIP_TUTORIAL'})}
              </button>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column flex-lg-row-fluid py-10'>
          <div className='d-flex flex-column flex-grow-1'>
            <div className='w-100 w-xl-800px p-10 px-lg-0 py-lg-15 mx-auto flex-grow-1'>
              <Outlet />
            </div>
            <div className='w-100 w-xl-800px d-flex pt-20 px-10 p-lg-0 justify-content-end align-items-end mx-auto flex-grow-1'>
              <div className='row'>
                <div className='d-flex m-0 p-0'>
                  {parseInt(step!) > 1 && (
                    <div className='me-2'>
                      <button
                        type='button'
                        className='btn btn-lg btn-outline btn-outline-dashed btn-outline-primary btn-active-primary'
                        onClick={prevStep}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-3 me-1'
                        />
                        {intl.formatMessage({id: 'GENERAL.BACK'})}
                      </button>
                    </div>
                  )}
                  <div>
                    {parseInt(step!) === navItems.length ? (
                      <button
                        type='button'
                        className='btn btn-lg btn-primary'
                        onClick={handleFinish}
                      >
                        {searchParams.get('taskId')
                          ? 'Ir para o Dashboard'
                          : intl.formatMessage({id: 'GENERAL.DO_IT_LATTER'})}
                      </button>
                    ) : (
                      <button
                        type='button'
                        className='btn btn-lg btn-light-primary'
                        onClick={nextStep}
                      >
                        {intl.formatMessage({id: 'GENERAL.NEXT'})}{' '}
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-1 me-0'
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </div>
  )
}

export {ManagerOnboardingPageWrapper}
