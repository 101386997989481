/* eslint-disable jsx-a11y/anchor-is-valid */
import {useQueryResponseData} from '../core/QueryResponseProvider'
import {StepProps, User} from '../core/_models'
import {ChangeEvent, useEffect, useState} from 'react'

export function ConfirmShiftTimeEdit({data, updateData, user}: Partial<StepProps> & {user: User}) {
  const [selectedOption, setSelectedOption] = useState('current')
  const weekdays: {[key: number]: string} = {
    1: 'domingo',
    2: 'segunda-feira',
    3: 'terça-feira',
    4: 'quarta-feira',
    5: 'quinta-feira',
    6: 'sexta-feira',
    7: 'sábado',
  }

  useEffect(() => {
    if (updateData) updateData({shiftTimeOption: selectedOption})
  }, [selectedOption])

  function formatHours(shiftTime?: string) {
    return shiftTime?.replace(
      /^(\d+)\.(\d{2})$/,
      (match, hora, minutos) => `${hora.padStart(2, '0')}:${minutos}`
    )
  }

  return (
    <div className='pb-5'>
      <div className='w-100'>
        <div className='fv-row mt-5'>
          <div className='col-12'>
            <div className='alert alert-warning mt-0 mb-6' role='alert'>
              <p className='mb-0'>
                Este usuário possui uma jornada de horas de {formatHours(user.shift_time ?? '00:00')}h nos dias{' '}
                {user.workdays.map((workday, index) => (
                  <>
                    {weekdays[workday]}
                    {user.workdays.length !== index + 1 ? ', ' : ' '}
                  </>
                ))}{' '}
                e será alterado para {formatHours(data?.shift_time!)}h nos dias{' '}
                {data?.workdays.map((workday, index) => (
                  <>
                    {weekdays[workday]}
                    {data.workdays.length !== index + 1 ? ', ' : '. '}
                  </>
                ))}
                Deseja alterar sua jornada desde quando esse usuário ingressou na equipe, ou daqui
                em diante?
              </p>
              <p className='mb-0'>Esta alteração pode impactar no relatório de time tracking.</p>
            </div>

            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input me-3'
                name='role'
                type='radio'
                value='current'
                id='kt_modal_update_role_option_0'
                checked={selectedOption === 'current'}
                onChange={(e) => setSelectedOption(e.target.value)}
              />

              <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                <div className='fw-bolder text-gray-800'>
                  Atualizar a jornada a partir da data de hoje.
                </div>
              </label>
            </div>

            <div className='form-check form-check-custom form-check-solid mt-2'>
              <input
                className='form-check-input me-3'
                name='role'
                type='radio'
                value='all'
                id='kt_modal_update_role_option_1'
                checked={selectedOption === 'all'}
                onChange={(e) => setSelectedOption(e.target.value)}
              />

              <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                <div className='fw-bolder text-gray-800'>
                  Atualizar a jornada desde quando o usuário foi cadastrado.
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
