import {useMemo} from 'react'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../../../modules/auth'
import {StepWelcome} from './StepWelcome'
import {StepWorkingDay} from './StepWorkingDay'
import {StepUsers} from './StepUsers'
import {StepClients} from './StepClients'
import {StepProjects} from './StepProjects'
import {StepTasks} from './StepTasks'

const StepsManagerWrapper = () => {
  const {step} = useParams()
  const {currentUser} = useAuth()
  const currentUserCostHour = useMemo(() => currentUser?.cost_hour, [])

  const stepsList = [StepWelcome, StepUsers, StepClients, StepProjects, StepTasks]
  let CurrentStep = StepWelcome

  if (!currentUser?.manager && !currentUser?.onboarded && !currentUserCostHour) {
    stepsList.splice(1, 0, StepWorkingDay)
  }

  if (step) {
    CurrentStep = stepsList[parseInt(step) - 1]
  }

  return <CurrentStep />
}

export {StepsManagerWrapper}
