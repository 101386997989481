/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { usePageData } from '../../../core'
import { CreateAppModal } from '../../../../partials'
import { KTSVG } from '../../../../helpers'
import { Button, Typography } from '@mui/material'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const ToolbarClassic = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const { pageButtons } = usePageData()

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3' id='toolbar_buttons'>
      {pageButtons &&
        pageButtons.map((button, key) =>
          button.tooltip ? (
            <OverlayTrigger
              placement='top'
              delay={{ show: 50, hide: 100 }}
              overlay={<Tooltip>{button.tooltip}</Tooltip>}
            >
              <span>
                <Button
                  key={key}
                  type='button'
                  href={button.link}
                  className={button.class}
                  disabled={button.disabled}
                  onClick={button.onClick}
                >
                  {button.icon && (
                    <KTSVG path={button.icon} className='svg-icon svg-icon-2 svg-icon-gray-800' />
                  )}
                  <div style={{ whiteSpace: 'nowrap' }}>{button.text}</div>
                  {button.icon2 && <i className={button.icon2}></i>}
                </Button>
              </span>
            </OverlayTrigger>
          ) : button.type === 'checkbox' ? (
            <>
              <Typography variant='body1' fontWeight={"bold"}>{button.text}</Typography>

              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className={`form-check-input h-20px w-30px `}
                  type='checkbox'
                  role='button'
                  onChange={button.onClick}
                />
              </label>
            </>
          ) : (
            <Button
              key={key}
              type='button'
              href={button.link}
              className={button.class}
              disabled={button.disabled}
              onClick={button.onClick}
            >
              {button.icon && (
                <KTSVG path={button.icon} className='svg-icon svg-icon-2 svg-icon-gray-800' />
              )}
              <div style={{ whiteSpace: 'nowrap' }}>{button.text}</div>
              {button.icon2 && <i className={button.icon2}></i>}
            </Button>
          )
        )}
      {/* 
        //@ts-ignore */}
      <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
    </div>
  )
}

export { ToolbarClassic }
