import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAuth} from '../../../../modules/auth'
import {InputCurrency, InputHour} from '../../../../../_metronic/helpers'
import {initialUser, User} from '../../core/_models'
import {updateUser} from '../../core/_requests'
import {secondsToHoursFormatNotSeconds} from '../../../../../_metronic/helpers'
import {Toast} from '../../../../../_metronic/partials/widgets'

export const StepWorkingDay: FC = () => {
  const intl = useIntl()
  const {currentUser, setCurrentUser} = useAuth()

  const editUserSchema = Yup.object().shape({
    cost_hour: Yup.number()
      .required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'}))
      .moreThan(0, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    shift_time: Yup.string()
      .min(1, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.MIN_LENGTH'}, {min: '1'}))
      .required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    workdays: Yup.array()
      .min(1, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'}))
      .required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
  })

  const [userForEdit] = useState<User>({
    cost_hour: currentUser?.cost_hour || initialUser.cost_hour,
    shift_time: currentUser?.shift_time || initialUser.shift_time,
    workdays: currentUser?.workdays || initialUser.workdays,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        await updateUser(values).then((user) => {
          setCurrentUser({
            ...currentUser!,
            shift_time: values.shift_time,
            cost_hour: values.cost_hour,
            workdays: values.workdays,
          })
          Toast.fire({
            icon: 'success',
            title: intl.formatMessage(
              {id: 'ALERT.LABEL.SUCCESS_UPDATE_ITEM'},
              {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.USER'})}
            ),
            customClass: {
              popup: 'bg-light-success',
              title: 'text-success',
            },
          })
        })
      } catch (ex) {
        console.error(ex)
        Toast.fire({
          icon: 'error',
          iconHtml: '<span>!</span>',
          title: intl.formatMessage(
            {id: 'ALERT.LABEL.ERROR_UPDATE_ITEM'},
            {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.USER'})}
          ),
          customClass: {
            popup: 'bg-light-danger',
            title: 'text-danger',
          },
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  var cost = formik.values.shift_time == null ? '0' : formik.values.shift_time
  if (formik.values.shift_time?.length == 4 && formik.values.shift_time) {
    cost = '0' + formik.values.shift_time
  }

  let hours = parseInt(cost.substr(0, 2))
  let minutes = parseInt(cost.substr(3, 2))

  hours = hours * 3600
  minutes = minutes * 60

  let seconds = secondsToHoursFormatNotSeconds((hours + minutes) * formik.values.workdays.length)

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bold text-dark'>Gerenciando seus dados</h2>
        <div className='text-gray-700 fw-semibold fs-5'>
          Para que o TaskRush possa calcular indicadores essenciais para a gestão dos seus projetos,
          como a margem de lucro, a viabilidade financeira e o acompanhamento das horas trabalhadas,
          é fundamental configurar seu custo por hora e sua jornada de trabalho. Essas informações
          permitem que o sistema ofereça insights precisos sobre o desempenho de cada projeto,
          ajudando você a tomar decisões estratégicas com mais segurança.
        </div>
      </div>
      <div className='fv-row'>
        <div className='row bg-body px-5 py-8'>
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className='col-4 mb-10'>
              {currentUser?.administrator && (
                <div className='fv-row mb-3'>
                  <label
                    htmlFor='cost_hour'
                    className='d-flex align-items-center fs-5 fw-semibold mb-2'
                  >
                    <span className='required'>
                      {intl.formatMessage({id: 'FORM.INPUT.NAME.COST_HOUR'})}
                    </span>
                    <OverlayTrigger
                      key='copy-to-clipboard'
                      placement='top'
                      overlay={
                        <Tooltip id='tooltip-copy-to-clipboard'>
                          O 'valor hora' representa o custo de uma hora do seu trabalho e pode ser
                          calculado dividindo seus custos mensais (como salários, despesas
                          operacionais e impostos) pelo total de horas trabalhadas no mês
                        </Tooltip>
                      }
                    >
                      <i className='fas fa-exclamation-circle ms-2 fs-7'></i>
                    </OverlayTrigger>
                  </label>
                  <InputCurrency
                    {...formik.getFieldProps('cost_hour')}
                    placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.VALUE'})}
                    type='text'
                    name='cost_hour'
                    id='cost_hour'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.cost_hour && formik.errors.cost_hour},
                      {
                        'is-valid': formik.touched.cost_hour && !formik.errors.cost_hour,
                      }
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'cost_hour',
                        e.target.value.replace('.', '').replace(',', '.')
                      )
                    }}
                  />
                  {formik.touched.cost_hour && formik.errors.cost_hour && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.cost_hour}</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className='col-12'>
              <h4>{intl.formatMessage({id: 'FORM.INPUT.NAME.WORK_DAYS'})}</h4>
              <div className='btn-group mb-2' role='group'>
                <input
                  {...formik.getFieldProps('workdays')}
                  type='checkbox'
                  className='btn-check'
                  id='monday'
                  name='workdays'
                  value={2}
                  onChange={formik.handleChange}
                  checked={formik.values.workdays.includes('2')}
                />
                <label
                  className='btn btn-outline btn-outline-primary rounded fw-normal py-1 px-3'
                  htmlFor='monday'
                >
                  {intl.formatMessage({id: 'WEEKDAYS.MONDAY'})}
                </label>

                <input
                  {...formik.getFieldProps('workdays')}
                  type='checkbox'
                  className='btn-check'
                  id='tuesday'
                  name='workdays'
                  value={3}
                  onChange={formik.handleChange}
                  checked={formik.values.workdays.includes('3')}
                />
                <label
                  className='btn btn-outline btn-outline-primary rounded fw-normal py-1 px-3'
                  htmlFor='tuesday'
                >
                  {intl.formatMessage({id: 'WEEKDAYS.TUESDAY'})}
                </label>

                <input
                  type='checkbox'
                  className='btn-check'
                  id='wednesday'
                  name='workdays'
                  value={4}
                  onChange={formik.handleChange}
                  checked={formik.values.workdays.includes('4')}
                />
                <label
                  className='btn btn-outline btn-outline-primary rounded fw-normal py-1 px-3'
                  htmlFor='wednesday'
                >
                  {intl.formatMessage({id: 'WEEKDAYS.WEDNESDAY'})}
                </label>

                <input
                  type='checkbox'
                  className='btn-check'
                  id='thursday'
                  name='workdays'
                  value={5}
                  onChange={formik.handleChange}
                  checked={formik.values.workdays.includes('5')}
                />
                <label
                  className='btn btn-outline btn-outline-primary rounded fw-normal py-1 px-3'
                  htmlFor='thursday'
                >
                  {intl.formatMessage({id: 'WEEKDAYS.THURSDAY'})}
                </label>

                <input
                  type='checkbox'
                  className='btn-check'
                  id='friday'
                  name='workdays'
                  value={6}
                  onChange={formik.handleChange}
                  checked={formik.values.workdays.includes('6')}
                />
                <label
                  className='btn btn-outline btn-outline-primary rounded fw-normal py-1 px-3'
                  htmlFor='friday'
                >
                  {intl.formatMessage({id: 'WEEKDAYS.FRIDAY'})}
                </label>

                <input
                  type='checkbox'
                  className='btn-check'
                  id='saturday'
                  name='workdays'
                  value={7}
                  onChange={formik.handleChange}
                  checked={formik.values.workdays.includes('7')}
                />
                <label
                  className='btn btn-outline btn-outline-primary rounded fw-normal py-1 px-3'
                  htmlFor='saturday'
                >
                  {intl.formatMessage({id: 'WEEKDAYS.SATURDAY'})}
                </label>

                <input
                  type='checkbox'
                  className='btn-check'
                  id='sunday'
                  name='workdays'
                  value={1}
                  onChange={formik.handleChange}
                  checked={formik.values.workdays.includes('1')}
                />
                <label
                  className='btn btn-outline btn-outline-primary rounded fw-normal py-1 px-3'
                  htmlFor='sunday'
                >
                  {intl.formatMessage({id: 'WEEKDAYS.SUNDAY'})}
                </label>
              </div>
              {formik.touched.workdays && formik.errors.workdays && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.workdays}</span>
                  </div>
                </div>
              )}
              <div className='d-flex align-items-center'>
                <div className='row justify-content-between'>
                  <div className='col-md-8'>
                    <div className='input-group'>
                      <span className='input-group-text'>
                        {intl.formatMessage({id: 'FORM.INPUT.NAME.WORKDAYS'})}
                      </span>
                      <InputHour
                        {...formik.getFieldProps('shift_time')}
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {'is-invalid': formik.touched.shift_time && formik.errors.shift_time},
                          {
                            'is-valid': formik.touched.shift_time && !formik.errors.shift_time,
                          }
                        )}
                        id='shift_time'
                        name='shift_time'
                        placeholder='00:00:00'
                        disabled={formik.isSubmitting}
                        onChange={(e: any) => {
                          const numbers = e.target.value.replace(/\D/g, '')
                          let hours = numbers.slice(0, -2) || '0'
                          let minutes = numbers.slice(-2).padStart(2, '0')
                          if (hours.length > 2) hours = hours.slice(-2)
                          hours = hours.padStart(2, '0')
                          const formated = hours + ':' + minutes
                          formik.setFieldValue('shift_time', formated)
                        }}
                      />
                    </div>
                    {formik.touched.shift_time && formik.errors.shift_time && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.shift_time}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-4 mt-3 ps-md-5'>
                    <span className='fs-4 fw-bold required'>
                      {intl.formatMessage(
                        {id: 'FORM.INPUT.NAME.HOURS_PER_WEEK'},
                        {
                          hours:
                            formik.values.workdays && formik.values.shift_time ? seconds : '00:00',
                        }
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-end pt-15'>
              <button
                type='submit'
                className='btn btn-primary py-2'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'GENERAL.SAVE_BUTTON'})}
                </span>
                {formik.isSubmitting && (
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
