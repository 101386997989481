import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Config, ConfigsQueryResponse, ResponseConfig} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const CONFIG_URL = `${API_URL}/api/config`
const GET_CONFIGS_URL = `${API_URL}/api/config`

function cancelSubscription(instanceId: number) {
  return axios.post(`${API_URL}/api/instances/${instanceId}/subscription/cancel`)
}

function updateSubscription({
  instanceId,
  planName,
  usersNumber,
  recurrenceType,
  value,
  cardId,
  coupon,
}: {
  instanceId: number
  planName: string
  usersNumber: number
  recurrenceType: string
  value: number
  cardId: number
  coupon?: string
}) {
  return axios.put(`${API_URL}/api/instances/${instanceId}/subscription/update`, {
    planName,
    usersNumber,
    recurrenceType,
    value,
    cardId,
    coupon,
  })
}

async function getSubscriptionAmount({
  planName,
  total_users,
  cycle,
  couponId,
}: {
  planName: string
  total_users: number
  cycle: string
  couponId?: number
}): Promise<{discountValue: number; newValue: number; success: boolean}> {
  const response = await axios.get(
    `${API_URL}/api/subscription/calculate-amount?planName=${planName}&total_users=${total_users}&cycle=${cycle}&couponId=${couponId}`
  )
  return response.data
}

function applyCoupon({
  planName,
  total_users,
  cycle,
  coupon,
}: {
  planName: string
  total_users: number
  cycle: string
  coupon: string
}) {
  return axios.post(`${API_URL}/api/subscription/apply-coupon`, {
    planName,
    total_users,
    cycle,
    coupon,
  })
}

const getConfigs = (): Promise<ConfigsQueryResponse> => {
  return axios.get(GET_CONFIGS_URL).then((d: AxiosResponse<ConfigsQueryResponse>) => d.data)
}

const getConfigById = (id: ID): Promise<ResponseConfig> =>
  axios.get(`${CONFIG_URL}/${id}`).then((response: AxiosResponse<ResponseConfig>) => response.data)

const createConfig = (config: Config): Promise<ResponseConfig> => {
  return axios
    .post(CONFIG_URL, config)
    .then((response: AxiosResponse<ResponseConfig>) => response.data)
}

const updateConfig = (config: Config): Promise<ResponseConfig> => {
  return axios
    .put(`${CONFIG_URL}/${config.id}`, config)
    .then((response: AxiosResponse<ResponseConfig>) => response.data)
}

const deleteConfig = (configId: ID): Promise<void> => {
  return axios.delete(`${CONFIG_URL}/${configId}/excluir`).then(() => {})
}

const deleteSelectedConfigs = (configIds: Array<ID>): Promise<void> => {
  const requests = configIds.map((id, index) => axios.delete(`${CONFIG_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getConfigs,
  deleteConfig,
  deleteSelectedConfigs,
  getConfigById,
  createConfig,
  updateConfig,
  cancelSubscription,
  updateSubscription,
  applyCoupon,
  getSubscriptionAmount,
}
