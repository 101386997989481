import { FC, useState, useMemo } from 'react'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import SourceEditingEditor from 'ckeditor5-build-classic-source-editing'
import { Project } from '../../../../app/modules/project/components/core/_models'
import { useAuth } from '../../../../app/modules/auth'
import { Link } from 'react-router-dom'
import { Toast } from '../../widgets'

type Props = {
  updateData: (fieldsToUpdate: Partial<Project>) => void
  saveData?: () => void
  value: string | number
  label?: string
  field: string
  project_id?: string;
}

const RichTextEdit: FC<Props> = ({ updateData, saveData, value, label, field, project_id }) => {
  const intl = useIntl()
  const [editableField, setEditableField] = useState<boolean>(false)
  const [showMore, setShowMore] = useState<boolean>(false)
  const [dataChanged, setDataChanged] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(true)
  const oldValue = useMemo(() => value, [saved])
  const { auth } = useAuth()

  const handleEditFieldClick = () => {
    if (editableField) {
      updateData({
        [field]: oldValue,
      })
      setSaved(false)
    }
    setEditableField(!editableField)
    setShowMore(false)
  }

  const saveField = () => {
    setEditableField(false)
    if (dataChanged && saveData) {
      saveData()
      setSaved(true)
    }
    setDataChanged(false)
  }

  return (
    <>
      <div className={`d-flex align-items-center justify-content-between ${project_id ? '' : 'mb-6'}`}>
        <div>
          <span className='fw-bold text-dark me-0'>{label}</span>
          <button onClick={handleEditFieldClick} className={clsx('btn',
            editableField ? 'btn p-0' :
              'btn btn-primary btn-sm w-fit-content py-2 px-3'
          )}>
            {!editableField && 'Editar '}
            <i
              className={clsx(
                'bi',
                editableField ? 'bi-x-square-fill text-danger fs-1' : 'fs-4 bi-pencil ms-1'
              )}
            ></i>
          </button>

          {editableField && (
            <>
              <button onClick={saveField} className='btn p-0'>
                <i className={clsx('fs-1 bi bi-check-square-fill ms-1 text-primary')}></i>
              </button>
            </>
          )}
        </div>

        {project_id && (
          <div className='d-flex align-items-center p-2'>
            <span>{intl.formatMessage({ id: 'SCHEDULE.LABEL.SCHEDULE_PUBLIC_DESCRIPTION' })}{' '}<Link to={`/public/document/${project_id}`} target='_blank'>{`${process.env.REACT_APP_URL
              }/public/document/${project_id.substring(0, 10)}...`}</Link></span>{' '}
            <button
              className='btn btn-primary btn-sm px-3 py-2 mt-2 mt-lg-0 ms-lg-4'
              title={intl.formatMessage({ id: 'GENERAL.COPY_LINK' })}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_URL}/public/document/${project_id}`
                )
                Toast.fire({
                  icon: 'success',
                  title: intl.formatMessage({ id: 'ALERT.LABEL.LINK_COPIED' }),
                  customClass: {
                    popup: 'bg-light-success',
                    title: 'text-success',
                  },
                })
              }}
            >
              <i
                className={clsx(
                  'bi',
                  'bi-clipboard fs-5 p-0'
                )}
              ></i>
            </button>
          </div>
        )}
      </div>

      <div className={`resume overflow-scroll transition-all ${showMore ? 'mh-1000px overflow-scroll' : 'mh-560px'}`}>

        <div className='project-richtext-container'>
          {editableField ? (
            <CKEditor
              //@ts-ignore
              editor={SourceEditingEditor}
              data={value ? value.toString() : ''}
              config={{
                simpleUpload: {
                  uploadUrl: `${process.env.REACT_APP_THEME_API_URL}/api/upload`,
                  headers: {
                    'Authorization': 'Bearer ' + auth?.api_token
                  }
                },
                mediaEmbed: {
                  previewsInData: true
                },
              }}
              onChange={(event, editor) => {
                if (value !== editor.data.get()) {
                  console.log(editor.data.get())

                  updateData({
                    [field]: editor.data.get(),
                  })
                  setDataChanged(true)
                  return
                }
                setDataChanged(false)
              }}
              onReady={(editor) => {
                editor.ui.view.editable.element &&
                  (editor.ui.view.editable.element.style.minHeight = '500px')
              }}
            />
          ) : (
            <>
              <div
                className={`resume overflow-hidden transition-all ${showMore ? 'mh-500px overflow-scroll' : 'mh-100px mh-md-45px'
                  }`}
              >
                <div dangerouslySetInnerHTML={{ __html: value?.toString() }} />
              </div>
              {
                value
                &&
                <div className='read-more text-center text-gray-200 mt-4'>
                  <span
                    className='text-gray-500 cursor-pointer'
                    onClick={() => {
                      setShowMore(!showMore)
                    }}
                  >
                    {showMore
                      ? intl.formatMessage({ id: 'GENERAL.SEE_LESS' })
                      : intl.formatMessage({ id: 'GENERAL.SEE_MORE' })}
                    <i className='bi bi-chevron-expand'></i>
                  </span>
                </div>
              }
            </>
          )}
        </div>
      </div>
    </>
  )
}

export { RichTextEdit }
