/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {StepperComponent} from '../../../assets/ts/components'
import {ProjectModalHeader} from './ProjectModalHeader'
import {ProjectModalContent} from './ProjectModalContent'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ProjectCreateModalStepper = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  return createPortal(
    <Modal
      id='kt_modal_edit_project'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      data-backdrop='static'
    >
      <ProjectModalHeader handleClose={handleClose} />
      <ProjectModalContent handleClose={handleClose} stepper={stepper} stepperRef={stepperRef} redirectAfterCreate={true} />
    </Modal>,
    modalsRoot
  )
}

export {ProjectCreateModalStepper}
