/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useAuth} from '../../../../modules/auth'

const StepWelcome: FC = () => {
  const {currentUser} = useAuth()

  return (
    <div className='w-100'>
      {/* <!--begin::Heading--> */}
      <div className='pb-10 pb-lg-15'>
        {/* <!--begin::Title--> */}
        <h2 className='fw-bold d-flex align-items-center text-dark'>
          Olá {currentUser?.name}, boas-vindas ao TaskRush
        </h2>
        {/* <!--end::Title--> */}
        {/* <!--begin::Notice--> */}
        <div className='text-gray-700 fw-semibold fs-5'>
          Aqui, faremos os primeiros cadastros para você tirar o máximo proveito da ferramenta. Você
          pode acessar essa introdução sempre que quiser.
          {/* Você pode consultar nossos tutoriais sempre que tiver dúvida na nossa{' '}
          <a className='link-primary fw-bold' href='#' target='_blank'>
            Página de Ajuda
          </a>
          . */}
        </div>
        {/* <!--end::Notice--> */}
      </div>
      {/* <!--end::Heading--> */}
      {/* <!--begin::Input group--> */}
      <div className='fv-row'>
        {/* <!--begin::Row--> */}
        <div className='row'>
          {/* <!--begin::Col--> */}
          <div className='col-12'>
            <div className='ratio ratio-16x9'>
              <iframe
                width='720'
                height='410'
                src='https://www.youtube.com/embed/0crkoc0URBQ?si=xfRweB5lubV-exHQ'
                title='TaskRush Onboarding'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                referrerPolicy='strict-origin-when-cross-origin'
                allowFullScreen
              ></iframe>
            </div>
          </div>
          {/* <!--end::Col--> */}
        </div>
        {/* <!--end::Row--> */}
      </div>
      {/* <!--end::Input group--> */}
    </div>
  )
}

export {StepWelcome}
