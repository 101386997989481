import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../helpers'
import {useAuth} from '../../../../../app/modules/auth'

type Props = {
  className?: string
}

const UpgradePlanWidget = ({className}: Props) => {
  const {currentUser} = useAuth()

  return (
    <div className={`card card-flush ${className}`}>
      <div
        className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
        style={{
          backgroundPosition: '100% 50%',
          backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
        }}
      >
        <div className='mb-10'>
          <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
            <p className='me-2'>
              Esse recurso está disponível apenas no
              <br />
              <span className='position-relative d-inline-block text-danger text-danger opacity-75-hover'>
                {currentUser?.administrator && !currentUser?.manager ? (
                  <Link to='/billings'>Plano PRO</Link>
                ) : (
                  'Plano PRO'
                )}

                <span className='position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100'></span>
              </span>
            </p>
            <p className='fs-3'>Faça o upgrade e aproveite todas as funcionalidades!</p>
          </div>

          {currentUser?.administrator && !currentUser?.manager && (
            <div className='text-center'>
              <Link to='/billings' className='btn btn-danger'>
                Faça o Upgrade Agora
              </Link>
            </div>
          )}
        </div>
        <img
          className='mx-auto h-150px h-lg-200px  theme-light-show'
          src={toAbsoluteUrl('/media/illustrations/misc/upgrade.svg')}
          alt=''
        />
        <img
          className='mx-auto h-150px h-lg-200px  theme-dark-show'
          src={toAbsoluteUrl('/media/illustrations/misc/upgrade-dark.svg')}
          alt=''
        />
      </div>
    </div>
  )
}

export {UpgradePlanWidget}
