import {useParams} from 'react-router-dom'
import {StepWelcome} from './StepWelcome'

const StepsUserWrapper = () => {
  const {step} = useParams()

  const stepsList = [StepWelcome]
  let CurrentStep = StepWelcome

  if (step) {
    CurrentStep = stepsList[parseInt(step) - 1]
  }

  return <CurrentStep />
}

export {StepsUserWrapper}
