import { Route, Routes, Outlet } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { PageTitle } from '../../../../_metronic/layout/core'
import { ScheduleViewWrapper } from './schedule-view/ScheduleView'
import { DescriptionViewWrapper } from './description-view/DescriptionView'

const DescriptionPage = () => {
    const intl = useIntl()

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path=':projectId'
                    element={
                        <>
                            <PageTitle>{intl.formatMessage({ id: 'PROJECT.LABEL.SHARED_PROJECT_SCOPE_SUMMARY_VISION' })}</PageTitle>
                            <DescriptionViewWrapper />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default DescriptionPage