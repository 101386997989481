import {FC, useState, createContext, useContext, useMemo, Dispatch, SetStateAction} from 'react'
import {ViewMode} from '../../../../../../_metronic/partials/gantt/src/types/public-types'
import {
  ID,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {Project} from './_models'

type ExtendedListViewContextProps = {
  view?: ViewMode;
  setView?: Dispatch<SetStateAction<ViewMode>>;
  isChecked?: boolean;
  setIsChecked?: Dispatch<SetStateAction<boolean>>;
  projectId?: ID;
  setProjectId?: Dispatch<SetStateAction<ID>>;
  project?: Project;
  setProject?: Dispatch<SetStateAction<Project | undefined>>;
}

const OverviewContext = createContext<ExtendedListViewContextProps>({})

const OverviewProvider: FC<WithChildren> = ({children}) => {
  const [view, setView] = useState<ViewMode>(ViewMode.Day)
  const [project, setProject] = useState<Project>()
  const [isChecked, setIsChecked] = useState(true)

  return (
    <OverviewContext.Provider
      value={{
        view,
        setView,
        project,
        setProject,
        isChecked,
        setIsChecked
      }}
    >
      {children}
    </OverviewContext.Provider>
  )
}

const useOverview = () => useContext(OverviewContext)

export {OverviewProvider, useOverview}
