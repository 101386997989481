import axios, {AxiosResponse} from 'axios'
import {CreditCard} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const CREDIT_CARD_URL = `${API_URL}/api/credit-cards`

export function updateAddress(id: number, data: any): Promise<{message: string}> {
  return axios
    .put(`${API_URL}/api/companys/${id}/atualizar-endereco`, data)
    .then((response: AxiosResponse<{message: string}>) => response.data)
}

export function addNewCreditCard(data: any): Promise<{message: string}> {
  return axios
    .post(`${CREDIT_CARD_URL}/novo`, data)
    .then((response: AxiosResponse<{message: string}>) => response.data)
}

export function getCreditCards(): Promise<CreditCard[]> {
  return axios
    .get(`${CREDIT_CARD_URL}`)
    .then((response: AxiosResponse<CreditCard[]>) => response.data)
}

export function markCreditCardAsPrimary(id: number): Promise<{message: string}> {
  return axios
    .put(`${CREDIT_CARD_URL}/${id}/mark-card-as-primary`)
    .then((response: AxiosResponse<{message: string}>) => response.data)
}

export function deleteCreditCard(id: number): Promise<{message: string}> {
  return axios
    .delete(`${CREDIT_CARD_URL}/${id}/excluir`)
    .then((response: AxiosResponse<{message: string}>) => response.data)
}
