import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../helpers'
import {ResponseTask, Task, TasksQueryResponse, Users, User, ResponseUser} from './_models'
import {
  ResponseTaskAttachement,
  TaskAttachement,
} from '../../../../../app/modules/project/components/tasks-list/TaskAttachment/core/_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const TASK_URL = `${API_URL}/api/tarefas`
const USER_URL = `${API_URL}/api/usuarios`

const getUsersInstanceTask = (): Promise<Users> => {
  return axios.get(`${TASK_URL}/busca_usuarios`).then((d: AxiosResponse<Users>) => d.data)
}

const getUsersTask = (): Promise<TasksQueryResponse> => {
  return axios
    .get(`${TASK_URL}/busca_usuarios-test`)
    .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}
const getTaskById = (id: ID): Promise<Task | undefined> => {
  return axios
    .get(`${TASK_URL}/${id}`)
    .then((response: AxiosResponse<Response<Task>>) => response.data)
    .then((response: Response<Task>) => response.data)
}

const createTask = (task: Task): Promise<ResponseTask> => {
  let formData = new FormData()

  if (task.creator_id) {
    formData.append('creator_id', task.creator_id)
  }
  if (task.name) {
    formData.append('name', task.name)
  }
  if (task.description) {
    formData.append('description', task.description)
  }
  if (task.expected_date) {
    formData.append('expected_date', task.expected_date)
  }
  if (task.file) {
    task.file.map((file) => {
      formData.append('documents[]', file)
    })
  }
  if (task.checklist_concluded) {
    formData.append('checklist_concluded', JSON.stringify(task.checklist_concluded))
  }
  if (task.checklist_name) {
    formData.append('checklist_name', JSON.stringify(task.checklist_name))
  }
  if (task.project_id) {
    formData.append('project_id', task.project_id)
  }
  if (task.users) {
    formData.append('users', JSON.stringify(task.users))
  }
  if (task.status) {
    formData.append('status', task.status)
  }
  if (task.status_name) {
    formData.append('status_name', task.status_name)
  }
  if (task.sprintId) {
    formData.append('sprintId', task.sprintId.toString())
  }
  return axios
    .post(`${TASK_URL}/new-task`, formData)
    .then((response: AxiosResponse<ResponseTask>) => response.data)
}

const updateTask = (task: Task): Promise<Task | undefined> => {
  return axios
    .post(`${TASK_URL}/${task.id}`, task)
    .then((response: AxiosResponse<Response<Task>>) => response.data)
    .then((response: Response<Task>) => response.data)
}

const deleteTask = (taskId: ID): Promise<void> => {
  return axios.delete(`${TASK_URL}/${taskId}`).then(() => {})
}

const deleteSelectedTasks = (taskIds: Array<ID>): Promise<void> => {
  const requests = taskIds.map((id, index) => axios.delete(`${TASK_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const updateUserTaskOnboarded =  (status: boolean): Promise<ResponseUser | undefined> => {
  return axios
    .put(`${USER_URL}/update-task-onboarded`, {'task_onboarded': status})
    .then((response: AxiosResponse<Response<ResponseUser>>) => response.data)
    .then((response: Response<ResponseUser>) => response.data)
}

export {
  deleteTask,
  deleteSelectedTasks,
  getTaskById,
  createTask,
  updateTask,
  getUsersTask,
  getUsersInstanceTask,
  updateUserTaskOnboarded
}
