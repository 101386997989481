import {FC, useState} from 'react'
import {TaskModal} from '../../../../../_metronic/partials/modals/task/TaskModal'

const StepTasks: FC = () => {
  const [showModalStepper, setShowModalStepper] = useState<boolean>(false)

  const openEditModal = () => {
    setShowModalStepper(true)
  }

  return (
    <>
      <div className='w-100'>
        {/* <!--begin::Heading--> */}
        <div className='pb-8 pb-lg-10'>
          {/* <!--begin::Title--> */}
          <h2 className='fw-bold text-dark'>Tudo pronto!</h2>
          {/* <!--end::Title--> */}
          {/* <!--begin::Notice--> */}
          <div className='text-gray-700 fw-semibold fs-5'>
            Agora que você já definiu seu perfil, cadastrou o primeiro cliente e projeto, o último
            passo é criar uma primeira tarefa. Mas, se quiser fazer isso depois, tudo bem.
          </div>
          {/* <!--end::Notice--> */}
        </div>
        {/* <!--end::Heading--> */}
        {/* <!--begin::Body--> */}
        <div className='mb-0'>
          {/* <!--begin::Alert--> */}
          {/* <!--begin::Notice--> */}
          <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            {/* <!--begin::Icon--> */}
            {/* <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg--> */}
            <span className='svg-icon svg-icon-2tx svg-icon-warning me-4'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  opacity='0.3'
                  x='2'
                  y='2'
                  width='20'
                  height='20'
                  rx='10'
                  fill='currentColor'
                />
                <rect
                  x='11'
                  y='14'
                  width='7'
                  height='2'
                  rx='1'
                  transform='rotate(-90 11 14)'
                  fill='currentColor'
                />
                <rect
                  x='11'
                  y='17'
                  width='2'
                  height='2'
                  rx='1'
                  transform='rotate(-90 11 17)'
                  fill='currentColor'
                />
              </svg>
            </span>
            {/* <!--end::Svg Icon--> */}
            {/* <!--end::Icon--> */}
            {/* <!--begin::Wrapper--> */}
            <div className='d-flex flex-stack flex-grow-1'>
              {/* <!--begin::Content--> */}
              <div className='fw-semibold'>
                <h4 className='text-gray-900 fw-bold'>
                  Cadastrar a primeira tarefa para mim, ou para outro membro
                </h4>
                <div className='fs-6 text-gray-700'>
                  Não se esqueça: para que o TaskRush te ajude a performar melhor nas tarefas, é
                  muito importate o lançamento de horas nas tarefas.{' '}
                  <button className='btn-link btn py-0 link-primary' onClick={openEditModal}>
                    Criar minha primeira tarefa
                  </button>
                </div>
              </div>
              {/* <!--end::Content--> */}
            </div>
            {/* <!--end::Wrapper--> */}
          </div>
          {/* <!--end::Notice--> */}
          {/* <!--end::Alert--> */}
        </div>
        {/* <!--end::Body--> */}
      </div>
      <TaskModal
        show={showModalStepper}
        handleClose={() => setShowModalStepper(false)}
      />
    </>
  )
}

export {StepTasks}
