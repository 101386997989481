import {Routes, Route} from 'react-router-dom'
import {ManagerOnboardingPageWrapper} from './onboarding-manager/ManagerOnboardingPageWrapper'
import {UserOnboardingPageWrapper} from './onboarding-user/UserOnboardingPageWrapper'
import {StepsManagerWrapper} from './onboarding-manager/steps/StepsManagerWrapper'
import {StepsUserWrapper} from './onboarding-user/steps/StepsUserWrapper'
import {useAuth} from '../../modules/auth'

const OnboardingPage = () => {
  const {currentUser} = useAuth()
  return (
    <Routes>
      {currentUser?.administrator ? (
        <Route element={<ManagerOnboardingPageWrapper />}>
          <Route path='/' element={<StepsManagerWrapper />} />
          <Route path=':step' element={<StepsManagerWrapper />} />
        </Route>
      ) : (
        <Route element={<UserOnboardingPageWrapper />}>
          <Route path='/' element={<StepsUserWrapper />} />
          <Route path=':step' element={<StepsUserWrapper />} />
        </Route>
      )}
    </Routes>
  )
}

export {OnboardingPage}
