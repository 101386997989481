import {ID, Response} from '../../../../_metronic/helpers'

// type DashboardProjects = Project & {
//   total_time?: number
//   total_time_percentage?: number
//   used_time?: number
// }

export type ResponseUser = {
  message?: string
  errors?: boolean
}

type UserHighlight = {
  name: string
  type: string
  avatar?: string
  date: string
}

export type Info = {
  projects_quantity: number
  user_tasks_quantity: number
  userTasksOpenQuantity: number
  userTaskDeliveryQuantity: number
  userTaskNotDeliveryQuantity: number
  hr_highlights: UserHighlight[]
  global_profit_margin: number
  closed_projects_percentage: number
}

// export type Projects = {
//   projects: DashboardProjects[]
// }


export type RecentActivities = {
    project_id: number
    project_name: string
    task_id: number
    userName: string
    userAvatar: string
    created_at: string
    description: string
}

export type ResponseDashboard = {
  info: Info
  message: string
  errors: boolean
}

export type FormatArray = {

  id: ID,
  day: number,
  day_name: string,
  month_name: string,
  total_time: number,
  total_time_porcent: number
  planned_time: number
  tooltip: string

}

export type TimeTrackingSquadRandomAdm = {
  timeTrackingSquadRandomAdm: {
    team_id: ID
    team_name: string
    id: ID
    name: string
    avatar: string
    job_role: string
    performance?: number
    hours_tracked?: number
    hours_total?: number
    has_time_tracking: boolean
    // instanceConfiguration: Config
  }[]
}

export type User = {
  cost_hour: number
  shift_time: string
  workdays: Array<string>
}

export type DashboardQueryResponse = Response<Info>
// export type ProjectsQueryResponse = Response<Projects>
export type TimeTrackingQueryResponse = Response<TimeTrackingSquadRandomAdm>
export type RecentActivitiesQueryResponse = Response<Array<RecentActivities>>

export const initialUser: User = {
  cost_hour: 0,
  shift_time: '',
  workdays: [],
}
