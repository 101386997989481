import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../_metronic/helpers'
import {User, UsersQueryResponse, ResponseUser} from './_models'
import {VacationPeriods} from '../../../../../app/modules/user/components/users-list/core/_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/api/usuarios`
const VACATION_URL = `${API_URL}/api/ferias`
const GET_USERS_URL = `${API_URL}/api/usuarios `

const userById = (users: UsersQueryResponse, id: number) => {
  return users.data.find((user) => user?.id === id)
}

const getUsers = (): Promise<UsersQueryResponse> => {
  return axios.get(GET_USERS_URL).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUsersBySelect = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${USER_URL}/find-users-select`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<ResponseUser> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<ResponseUser>) => response.data)
}

const getEmailValid = (user: User): Promise<ResponseUser | undefined> => {
  return axios
    .post(`${USER_URL}/verify_email`, user)
    .then((response: AxiosResponse<ResponseUser>) => response.data)
}

const createUser = (user: User): Promise<ResponseUser | undefined> => {
  let formData = new FormData()
  if (user.avatar) {
    formData.append('avatar', user.avatar)
  }
  formData.append('name', user.name)
  formData.append('username', user.username)
  formData.append('password', user.password)
  formData.append('email', user.email)
  formData.append('shift_time', user.shift_time)
  for (var i = 0; i < user.workdays.length; i++) {
    formData.append('workdays[]', user.workdays[i].toLocaleString())
  }
  // formData.append('workdays[]',user.workdays.toLocaleString());
  if (user.phone) {
    formData.append('phone', user.phone)
  }
  formData.append('birthday', user.birthday)
  formData.append('in_company_since', user.in_company_since)
  formData.append('cost_hour', user.cost_hour.toString())
  formData.append('job_role', user.job_role)
  formData.append('administrator', user.administrator.toString())
  formData.append('manager', user.manager.toString())
  formData.append('format_timeShift', user.format_timeShift)
  // formData.append('active', user.active.toLocaleString())
  return axios
    .post(`${USER_URL}/novo`, formData)
    .then((response: AxiosResponse<ResponseUser>) => response.data)
}

const updateUser = (
  user: User & {option?: string; shiftTimeOption?: string}
): Promise<ResponseUser | undefined> => {
  let formData = new FormData()
  if (user.avatar) {
    formData.append('avatar', user.avatar)
  }
  formData.append('name', user.name)
  formData.append('username', user.username)
  if (user.password) {
    formData.append('password', user.password)
  }
  formData.append('email', user.email)
  if (user.shift_time) {
    formData.append('shift_time', user.shift_time)
  }
  for (var i = 0; i < user.workdays.length; i++) {
    formData.append('workdays[]', user.workdays[i].toLocaleString())
  }
  if (user.phone) {
    formData.append('phone', user.phone)
  }
  if (user.birthday) {
    formData.append('birthday', user.birthday)
  }
  if (user.in_company_since) {
    formData.append('in_company_since', user.in_company_since)
  }
  if (user.cost_hour) {
    formData.append('cost_hour', user.cost_hour.toString())
  }
  if (user.job_role) {
    formData.append('job_role', user.job_role)
  }
  formData.append('administrator', user.administrator.toString())
  formData.append('manager', user.manager.toString())
  formData.append('format_timeShift', user.format_timeShift)
  if (user.option) formData.append('option', user.option)
  if (user.shiftTimeOption) formData.append('shiftTimeOption', user.shiftTimeOption)

  // formData.append('active', user.active.toLocaleString())

  // formData.append('creator_id',user.creator_id);
  // formData.append('instance_id',user.instance_id);
  return axios
    .post(`${USER_URL}/${user.id}/editar`, formData)
    .then((response: AxiosResponse<ResponseUser>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}/excluir`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id, index) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const createVacation = async (vacation: VacationPeriods): Promise<VacationPeriods | undefined> => {
  let formData = new FormData()

  formData.append('user_id', vacation.user_id!.toString())
  formData.append('begin_date', vacation.begin_date)
  formData.append('end_date', vacation.end_date)

  return axios.post(`${VACATION_URL}/novo`, formData).then((response) => response.data)
}

const updateVacation = async (vacation: VacationPeriods): Promise<VacationPeriods | undefined> => {
  return axios.put(`${VACATION_URL}/${vacation.id}/editar`, vacation).then((response) => {
    return response.data
  })
}

const deleteVacation = async (vacationId: ID): Promise<void> => {
  return axios.delete(`${VACATION_URL}/${vacationId}/excluir`).then(() => {})
}

export {
  getUsers,
  deleteUser,
  userById,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  getEmailValid,
  getUsersBySelect,
  createVacation,
  updateVacation,
  deleteVacation,
}
